













































import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import MoneyInput from '@/partials/forms/components/MoneyInput.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
@Component({
  components: {
    MoneyInput,
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class SubscriptionTypeItemForm extends Mixins(FlashMessageMixin, ValidatorConfigMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionTypeId!: string
  @Prop({ type: String }) subscriptionTypeItemId!: string

  form: Record<string, any> = {
    name: '',
    price: 0,
    eventSubscriptionTypeId: '',
    confirm: '',
    errors: {
      confirm: '',
      name: '',
      price: '',
      eventSubscriptionTypeId: ''
    }
  }

  created () {
    if (this.subscriptionTypeItemId) {
      axios.get(`/event/${this.eventId}/subscription/type/${this.subscriptionTypeId}/items/${this.subscriptionTypeItemId}`)
        .then(response => {
          Object.assign(this.form, response.data.data)
        })
    }
    this.form.eventSubscriptionTypeId = this.$route.params.subscriptionId
  }

  done = true

  submit () {
    this.done = false
    let requestUrl = `/event/${this.eventId}/subscription/type/${this.subscriptionTypeId}/items/`
    const requestVerb = this.isEditForm() ? 'put' : 'post'

    if (this.isEditForm()) {
      requestUrl += this.subscriptionTypeItemId
    }

    axios[requestVerb](requestUrl, snakeCaseKeys(this.form, { deep: true }))
      .then(() => {
        const flashMessage: flashMessage = {
          message: `Item ${this.isEditForm() ? 'editado' : 'cadastrado'} com sucesso.`,
          type: 'success'
        }

        this.$router.push({
          name: 'EventSubscriptionItems',
          params: {
            eventId: this.$route.params.eventId,
            subscriptionId: this.$route.params.subscriptionId
          }
        }, () => this.setFlashMessage(flashMessage))
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      confirm: '',
      name: '',
      price: '',
      eventSubscriptionTypeId: ''
    }
  }

  private isEditForm () {
    return !!this.subscriptionTypeItemId
  }
}
