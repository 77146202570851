var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',{staticClass:"text-3xl"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-8",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome","invalid-message":_vm.form.errors.name || errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-4",attrs:{"name":"price","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MoneyInput',{attrs:{"label":"Preço","invalid-message":_vm.form.errors.price || errors[0]},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})]}}],null,true)})],1),_c('div',{staticClass:"w-full max-w-xs pt-4"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),_c('div',{staticClass:"flex flex-col md:flex-row w-full"},[(_vm.done)?_c('cv-button',{attrs:{"type":"submit"}},[_vm._v(" Salvar Item ")]):_c('cv-button-skeleton')],1)]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }